<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
        <path
            fill-rule="evenodd"
            d="M14 0C6.26800781 0 0 6.26800781 0 14c0 6.9878047 5.11959766 12.7796484 11.8125 13.8298398V18.046875H8.2578125V14H11.8125v-3.084375c0-3.50875 2.0901016-5.446875 5.2880078-5.446875 1.5317149 0 3.1338672.2734375 3.1338672.2734375V9.1875h-1.7653945c-1.7391172 0-2.2814805 1.0791758-2.2814805 2.1863242V14h3.8828125l-.6207031 4.046875H16.1875v9.7829648C22.8804023 26.7796484 28 20.9878047 28 14c0-7.73199219-6.2680078-14-14-14"
        />
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
